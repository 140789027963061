* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

.gradient {
  background: radial-gradient(
    circle,
    rgba(2, 0, 36, 1) 0%,
    rgba(6, 6, 59, 1) 66%,
    rgba(9, 9, 57, 1) 73%
  );
}
